import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"

import Layout from "../components/layout"

export default class extends React.Component {
  state = {
    compania: {},
    categoria: {},
    cobertura: {},
    formsData: {},
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState(this.props.location.state)
    } else {
      navigate("/")
    }
  }

  render() {
    const { compania, cobertura, formsData } = this.state
    return (
      <Layout>

        <section className="pb-5 pt-5" id="faq">

          <h2 className="h3 block-title text-center mb-5">Resumen Contratación
            <small>Muchas gracias por confiar en nosotros. <br/><br/>
            <strong className="alert-primary">Su contratación está en proceso y pronto un asesor se contactará con usted.</strong></small>
          </h2>

          <div className="container">

            <div className="row">

              <div className="col-md-6 offset-md-3 pb-5">


                <div className="card blog-card">
                  <div className="card-body">
                    {compania.logo && (
                        <img
                            src={require(`../assets/old/img/logos-ase/${compania.logo}`)}
                            alt={compania.nombre}
                            className="d-block mx-auto mb-4"
                        />
                    )}
                    <h6 className="post-title city">Datos de contacto</h6>

                    <p className="text-muted testimonial-p">
                      <strong>Nombre:</strong> {formsData.nombre} <br/>
                      <strong>Celular:</strong> {formsData.celular} <br/>
                      <strong>Email:</strong> {formsData.email}
                    </p>

                    <h4 className="post-title city">Datos de la cobertura</h4>
                    <p className="text-muted testimonial-p">
                      <strong>Marca:</strong> {formsData.marcaDescripcion} <br/>
                      <strong>Versión:</strong> {formsData.versionDescripcion} <br/>
                      <strong>Año:</strong> {formsData.anio} <br/>
                      <strong>Compañía:</strong> {compania.nombre} <br/>
                      <strong>Cobertura:</strong> {cobertura.nombre} <br/>

                    </p>


                  </div>
                  <div className="card-footer"><a className="post-author" href="/">
                    <div className="post-author-name" style={{ fontSize: "1.2rem"}}>Cuota Mensual</div>
                  </a>
                    <div className="post-meta"><span className="text-success font-weight-bold" style={{ fontSize: "2rem"}}>${cobertura.cuota}</span></div>
                  </div>
                </div>


              </div>


            </div>

            <div className="text-center my-5">
              <Link className="btn btn-style-6 btn-primary mr-3 mb-3" to="/">
                Realizar una nueva cotización
              </Link>
            </div>


          </div>

        </section>


    {/*
        <div className="container">
          <ul>
            <li>Nombre: {formsData.nombre}</li>
            <li>Celular: {formsData.celular}</li>
            <li>Email: {formsData.email}</li>
            <li>Compañía: {compania.nombre}</li>
            <li>
              Logo compañía:
              {compania.logo && (
                <img
                  src={require(`../assets/old/img/logos-ase/${compania.logo}`)}
                  alt={compania.nombre}
                />
              )}
            </li>
            <li>Cobertura contratada: {cobertura.nombre}</li>
            <li>Cuota: {cobertura.cuota}</li>
          </ul>
          <p>
            <b>Más data por las dudas</b>
          </p>
          <p>formsData</p>
          <pre>{JSON.stringify(formsData, null, 2)}</pre>
          <p>cobertura</p>
          <pre>{JSON.stringify(cobertura, null, 2)}</pre>
          <p>compania</p>
          <pre>{JSON.stringify(compania, null, 2)}</pre>
          <p>categoria</p>
          <pre>{JSON.stringify(categoria, null, 2)}</pre>
        </div>

    */}

      </Layout>
    )
  }
}
